<template>
  <section-wrap>
    <template #title>
      <div class="d-flex">
        <h3 class="section-title mr-5">
          Projects
        </h3>
        <project-navigation />
      </div>
    </template>
    <section-container
      :header="false"
    >
      <template #body>
        <router-view />
      </template>
    </section-container>
  </section-wrap>
</template>

<script>
import SectionWrap from '@/components/collections/SectionWrap/SectionWrap';
import SectionContainer from '@/components/collections/SectionContainer/SectionContainer';
import ProjectNavigation from '@/views/Project/ProjectNavigation';

export default {
  name: 'Project',
  components: { ProjectNavigation, SectionContainer, SectionWrap },
  computed: {
    selectedWorkspace() {
      return this.$store.getters.getSelectedWorkspace.slug;
    },
    getPermissions() {
      return this.$store.getters.getPermissions;
    },
  },
  watch: {
    getPermissions: {
      handler(permissions) {
        if (!this.selectedWorkspace) this.$router.push('/dashboard');

        if (Object.keys(permissions).length > 0) {
          if (permissions.can_projects) return;

          this.$router.push('/dashboard');
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.$store.dispatch('GET_COLORS');
  },
};
</script>
